// "use strict";
// // /* ----- qa ----- */
// jQuery(function($) {
//   // $をjQueryとして使用できるようにする
//   $(".js-qa").on("click", function() {
//     $(this).next().slideToggle();
//     $(this).toggleClass("is-active");
//   });
// });



// /* ----- 詳細トップのURLコピーボタン ----- */
// // ClipboardJS を利用する場合
// jQuery(document).ready(function($) {
//   var clipboard = new ClipboardJS('.copy_btn');  // clipboard.min.jsが動作する要素をクラス名で指定

//   // コピー成功時の処理
//   clipboard.on('success', function(e) {
//     var $btn = $(e.trigger);  // クリックされたボタン要素
//     $btn.addClass('copied');  // ボタンの色などを変更するためにクラスを追加

//     // 必要に応じて一定時間後にクラスを削除
//     setTimeout(function(){
//       $btn.removeClass('copied');
//     }, 3000);  // 2秒後に元の状態に戻す

//     e.clearSelection();  // コピー後に選択をクリア
//   });

//   // コピー失敗時の処理（必要であれば追加）
//   clipboard.on('error', function(e) {
//     alert('コピーに失敗しました');
//   });
// });

// /* ----- 詳細ボトムのURLコピーボタン ----- */
// jQuery(document).ready(function($) {
//   var clipboard = new ClipboardJS('.shareBottom_copy_btn');  // clipboard.min.jsが動作する要素をクラス名で指定

//   // コピー成功時の処理
//   clipboard.on('success', function(e) {
//     var $btn = $(e.trigger);  // クリックされたボタン要素
//     $btn.addClass('copied');  // ボタンの色などを変更するためにクラスを追加

//     // <span>のテキストを「コピーしました」に変更
//     $btn.find('.shareBottom_url_text').text('コピーしました');

//     // 必要に応じて一定時間後にクラスを削除し、元のテキストに戻す
//     setTimeout(function(){
//       $btn.removeClass('copied');
//       $btn.find('.shareBottom_url_text').text('記事タイトルとURLをコピーをする');  // 元のテキストに戻す
//     }, 3000);  // 3秒後に元の状態に戻す

//     e.clearSelection();  // コピー後に選択をクリア
//   });

//   // コピー失敗時の処理（必要であれば追加）
//   clipboard.on('error', function(e) {
//     alert('コピーに失敗しました');
//   });
// });

// /* ----- 目次もっと見るボタン ----- */
// document.addEventListener('DOMContentLoaded', function() {
//     const tocContainer = document.querySelector('#ez-toc-container');
//     const tocItems = tocContainer.querySelectorAll('ul li');
//     if (tocItems.length > 3) {
//         // もっと見るボタンを追加
//         const showMoreBtn = document.createElement('span');
//         showMoreBtn.classList.add('show-more-btn');
//         showMoreBtn.innerText = '目次を全て見る';
//         tocContainer.appendChild(showMoreBtn);

//         // ボタンクリックで項目を表示/非表示切り替え
//         showMoreBtn.addEventListener('click', function() {
//             tocContainer.classList.toggle('show-more');
//             if (tocContainer.classList.contains('show-more')) {
//                 showMoreBtn.innerText = '閉じる';
//             } else {
//                 showMoreBtn.innerText = '目次を全て見る';
//             }
//         });
//     }
// });




/* ----- qa ----- */
"use strict";
jQuery(function($) {
  // $をjQueryとして使用できるようにする
  $(".js-qa").each(function() {
    if ($(this).next().length) {
      $(this).on("click", function() {
        $(this).next().slideToggle();
        $(this).toggleClass("is-active");
      });
    }
  });
});

/* ----- 詳細トップのURLコピーボタン ----- */
// ClipboardJS を利用する場合
jQuery(document).ready(function($) {
  if ($('.copy_btn').length) {
    var clipboard = new ClipboardJS('.copy_btn');  // clipboard.min.jsが動作する要素をクラス名で指定

    // コピー成功時の処理
    clipboard.on('success', function(e) {
      var $btn = $(e.trigger);  // クリックされたボタン要素
      $btn.addClass('copied');  // ボタンの色などを変更するためにクラスを追加

      // 必要に応じて一定時間後にクラスを削除
      setTimeout(function(){
        $btn.removeClass('copied');
      }, 3000);  // 2秒後に元の状態に戻す

      e.clearSelection();  // コピー後に選択をクリア
    });

    // コピー失敗時の処理（必要であれば追加）
    clipboard.on('error', function(e) {
      alert('コピーに失敗しました');
    });
  }
});

/* ----- 詳細ボトムのURLコピーボタン ----- */
jQuery(document).ready(function($) {
  if ($('.shareBottom_copy_btn').length) {
    var clipboard = new ClipboardJS('.shareBottom_copy_btn');  // clipboard.min.jsが動作する要素をクラス名で指定

    // コピー成功時の処理
    clipboard.on('success', function(e) {
      var $btn = $(e.trigger);  // クリックされたボタン要素
      $btn.addClass('copied');  // ボタンの色などを変更するためにクラスを追加

      // <span>のテキストを「コピーしました」に変更
      $btn.find('.shareBottom_url_text').text('コピーしました');

      // 必要に応じて一定時間後にクラスを削除し、元のテキストに戻す
      setTimeout(function(){
        $btn.removeClass('copied');
        $btn.find('.shareBottom_url_text').text('記事タイトルとURLをコピーをする');  // 元のテキストに戻す
      }, 3000);  // 3秒後に元の状態に戻す

      e.clearSelection();  // コピー後に選択をクリア
    });

    // コピー失敗時の処理（必要であれば追加）
    clipboard.on('error', function(e) {
      alert('コピーに失敗しました');
    });
  }
});




/* ----- 目次もっと見るボタン ----- */
document.addEventListener('DOMContentLoaded', function() {
  const tocContainer = document.querySelector('#ez-toc-container');
  if (tocContainer) {
    const tocItems = tocContainer.querySelectorAll('ul li');
    // 既にボタンが存在する場合は追加しない
    if (tocItems.length > 3 && !tocContainer.querySelector('.show-more-btn')) {
      // もっと見るボタンを追加
      const showMoreBtn = document.createElement('span');
      showMoreBtn.classList.add('show-more-btn');
      showMoreBtn.innerText = '目次を全て見る';
      tocContainer.appendChild(showMoreBtn);

      // ボタンクリックで項目を表示/非表示切り替え
      showMoreBtn.addEventListener('click', function() {
        tocContainer.classList.toggle('show-more');
        if (tocContainer.classList.contains('show-more')) {
          showMoreBtn.innerText = '閉じる';
        } else {
          showMoreBtn.innerText = '目次を全て見る';
        }
      });
    }
  }
});



/* ----- 投稿の箇条書きリスト liの中のulにクラス付与 ----- */
document.querySelectorAll('.article-column ul li, .article-content ul li').forEach(function(item) {
  if (item.querySelector('ul')) {
    item.classList.add('has_ul');
  }
});


/* ----- 目次の上にバナー自動挿入 ----- */
document.addEventListener("DOMContentLoaded", function () {
    const tocContainer = document.getElementById("ez-toc-container");
    // 既に挿入済みか確認する
    if (tocContainer && !document.querySelector('.app_baner')) {
        const bannerHtml = `
            <div class="app_baner">
              <a href="/search/clinic?tags=9,25,8&limit=20&order=latest&by=troubles&discount_in_progress=true">
                <picture>
                  <source srcset="wp-content/uploads/new_baner02.webp" media="(min-width: 768px)" type="image/png">
                  <img src="wp-content/uploads/new_baner02_sp.webp" alt="アプリはこちら" width="686" height="121">
                </picture>
              </a>
            </div>
        `;
        tocContainer.insertAdjacentHTML('beforebegin', bannerHtml);
    }
});



/* ---- contact form 7 郵便番号バリテーション ----- */
document.addEventListener('DOMContentLoaded', function () {
  const postalField = document.querySelector('input[name="hfk-clinic-postcode"]');
  const errorMessage = document.createElement('span');
  errorMessage.style.color = '#d93b11';
  errorMessage.style.fontWeight = 'bold';
  errorMessage.style.display = 'none';
  postalField.parentNode.appendChild(errorMessage);

  // フォーカスが外れたときにバリデーションを実行
  postalField.addEventListener('blur', function () {
    const postalCodePattern = /^\d{3}-\d{4}$/;
    if (!postalCodePattern.test(postalField.value)) {
      errorMessage.textContent = '郵便番号は123-4567の形式（半角）で入力してください。';
      errorMessage.style.display = 'block';
    } else {
      errorMessage.style.display = 'none';
    }
  });
});
